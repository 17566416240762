




import { Component, Vue } from 'vue-property-decorator'

import MentorExercisesModule from '@/store/modules/mentor/exercises'

@Component
export default class GoToManagerExerciseItem extends Vue {
  private get exerciseUUID() {
    return this.$route.params.exerciseUUID
  }

  private get groupID() {
    return this.$route.params.groupID
  }

  private get masterID() {
    return this.$route.params.masterID
  }

  private get taskUUID() {
    return this.$route.params.taskUUID
  }

  private mounted() {
    MentorExercisesModule.setExercise(null)
    this.$router.replace({
      name: 'manager.education.exercises.item.task.master.messages',
      params: {
        exerciseUUID: this.exerciseUUID,
        groupID: this.groupID,
        masterID: this.masterID,
        taskUUID: this.taskUUID,
      },
    })
  }
}
